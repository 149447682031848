import React from 'react';

import bingLogo from '../../images/logo-bing.png';
import googleLogo from '../../images/logo-google.png';
import yahooLogo from '../../images/logo-yahoo.png';

export default function Example() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <h2 className="max-w-md mx-auto text-3xl font-title font-extrabold text-green-brand text-center lg:max-w-xl lg:text-left">
            Moteurs de recherche analysés
          </h2>
          <div className="flow-root self-center mt-8 lg:mt-0">
            <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
              <div className="mt-4 ml-4 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-2">
                <img className="h-8" src={bingLogo} alt="Bing" />
              </div>
              <div className="mt-4 ml-4 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-2">
                <img className="h-8" src={googleLogo} alt="Google" />
              </div>
              <div className="mt-4 ml-4 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-2">
                <img className="h-8" src={yahooLogo} alt="Yahoo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
